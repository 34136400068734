import Vue from 'vue'
import VueDisableInterpolation from 'vue-disable-interpolation'
import Buefy from 'buefy'
import PortalVue from 'portal-vue'
import Croppa from 'vue-croppa'
import { parse, format } from 'date-fns'
import ActionBox from 'components/ActionBox'
import ActionBoxBody from 'components/ActionBoxBody'
import ActionBoxes from 'components/ActionBoxes'
import TheActionBar from 'components/TheActionBar'
import HeaderH2 from 'components/HeaderH2'
import HeaderH3 from 'components/HeaderH3'
import HeaderH4 from 'components/HeaderH4'
import Money from 'components/Money'
import ErrorNotification from 'components/ErrorNotification'
import SimpleErrorNotification from 'components/SimpleErrorNotification'
import BaseTextInput from 'components/BaseTextInput'
import BaseInputPassword from 'components/my-commu/BaseInputPassword'
import BaseSwitchInput from 'components/BaseSwitchInput'
import BaseSelectInput from 'components/BaseSelectInput'
import BaseSelectInterestedFreeTag from 'components/BaseSelectInterestedFreeTag'
import BaseCheckboxInput from 'components/BaseCheckboxInput'
import BaseCheckboxButton from 'components/BaseCheckboxButton'
import BaseCheckboxesInput from 'components/BaseCheckboxesInput'
import BaseCounterButton from 'components/BaseCounterButton'
import BaseDateInput from 'components/BaseDateInput'
import BaseTimeInput from 'components/BaseTimeInput'
import BaseRadioInput from 'components/BaseRadioInput'
import BaseSubmitButton from 'components/BaseSubmitButton'
import BaseLabel from 'components/BaseLabel'
import DateTimePicker from 'components/DateTimePicker'
import DatePicker from 'components/DatePicker'
import Calendar from 'components/Calendar'
import BirthdayPicker from 'components/BirthdayPicker'
import NestedRequestSchedulesForm from 'components/NestedRequestSchedulesForm'
import NestedEventSchedulesForm from 'components/NestedEventSchedulesForm'
import NestedTicketChildProfilesForm from 'components/NestedTicketChildProfilesForm'
import NestedCorporateProfileForm from 'components/NestedCorporateProfileForm'
import NestedEventMailingListFilterForm from 'components/NestedEventMailingListFilterForm'
import NestedCouponForm from 'components/NestedCouponForm'
import TheChat from 'components/TheChat'
import ProfileSectionHierarchy from 'components/ProfileSectionHierarchy'
import CustomValueCheckboxInput from 'components/CustomValueCheckboxInput'
import TheCorporateUser from 'components/TheCorporateUser'
import TheApplication from 'components/TheApplication'
import TheCorpApplication from 'components/TheCorpApplication'
import InlineFrameContents from 'components/InlineFrameContents'
import TheProfileForm from 'components/TheProfileForm'
import TheProfile from 'components/TheProfile'
import TheEventForm from 'components/TheEventForm'
import TheAgeRangeForm from 'components/TheAgeRangeForm'
import FaceIcon from 'components/FaceIcon'
import FaceIconRelations from 'components/FaceIconRelations'
import EditableFaceIcon from 'components/EditableFaceIcon'
import EditPasswordForm from 'components/EditPasswordForm'
import EventDetailForMyCommu from 'components/EventDetailForMyCommu'
import EventTab from 'components/EventTab'
import EventList from 'components/EventList'
import EventListItem from 'components/EventListItem'
import EventListItemForMyCommu from 'components/EventListItemForMyCommu'
import AssignmentMailBodyInput from 'components/AssignmentMailBodyInput'
import TheCommunityForm from 'components/TheCommunityForm'
import Notice from 'components/Notice'
import FriendGauge from 'components/FriendGauge'
import BasePanelBlock from 'components/BasePanelBlock'
import TheCommunity from 'components/TheCommunity'
import BaseTab from 'components/BaseTab'
import TheBankAccountForm from 'components/TheBankAccountForm'
import SharingCandidateSelectInput from 'components/SharingCandidateSelectInput'
import HistoryActionBox from 'components/HistoryActionBox'
import BaseOptionButton from 'components/BaseOptionButton'
import BaseHelpButton from 'components/BaseHelpButton'
import BaseSearchButton from 'components/BaseSearchButton'
import ImageModal from 'components/ImageModal'
import TheNotification from 'components/TheNotification'
import TheBillingForm from 'components/TheBillingForm'
import ChildrenSelectInput from 'components/ChildrenSelectInput'
import SharingCandidateUser from 'components/SharingCandidateUser'
import TheEvent from 'components/TheEvent'
import TheEvents from 'components/TheEvents'
import LabelSection from 'components/LabelSection'
import TheHistory from 'components/TheHistory'
import TheTicketForm from 'components/TheTicketForm'
import TheEditStaffCandidateForm from 'components/TheEditStaffCandidateForm'
import ShareFriendCard from 'components/ShareFriendCard'
import ShareFriendCardModal from 'components/ShareFriendCardModal'
import ShareFriendCardModalLink from 'components/ShareFriendCardModalLink'
import ShareFriendAnimation from 'components/ShareFriendAnimation'
import TheFriendships from 'components/TheFriendships'
import PhotoUploaderBox from 'components/PhotoUploaderBox'
import PhotoUploaderOuter from 'components/PhotoUploaderOuter'
import TheSiteLaw from 'components/TheSiteLaw'
import TheInvitingForm from 'components/TheInvitingForm'
import AddonTextInput from 'components/AddonTextInput'
import PhotoUploader from 'components/PhotoUploader'
import TheChats from 'components/TheChats'
import SharingCard from 'components/SharingCard'
import RecommendNeighbor from 'components/RecommendNeighbor'
import AnnouncementList from 'components/AnnouncementList'
import UnregisteredMessage from 'components/UnregisteredMessage'
import CandidateLink from 'components/CandidateLink'
import EntryDetail from 'components/EntryDetail'
import SearchResultZero from 'components/SearchResultZero'
import CommunityParticipationUser from 'components/CommunityParticipationUser'
import InvitationSignIn from 'components/InvitationSignIn'
import SnsShareButton from 'components/SnsShareButton'
import HorizontalFaceIcons from 'components/HorizontalFaceIcons'
import TheShareableForm from 'components/TheShareableForm'
import LabeledActionBoxes from 'components/LabeledActionBoxes'
import MamaSupporterCard from 'components/MamaSupporterCard'
import SampleMark from 'components/SampleMark'
import TabNoIcon from 'components/TabNoIcon'
import TheUsersMap2 from 'components/TheUsersMap2'
import MessageItem from 'components/MessageItem'
import SharingFriendButton from 'components/SharingFriendButton'
import ShareableCandidacyButton from 'components/ShareableCandidacyButton'
import Balloon from 'components/Balloon'
import ButtonSquareEdge from 'components/ButtonSquareEdge'
import StatGeoFields from 'components/StatGeoFields'
import MamaSupporterAbilities from 'components/MamaSupporterAbilities'
import DeviseNotSignedIn from 'components/DeviseNotSignedIn'
import TheChildProfileForm from 'components/TheChildProfileForm'
import TheEntryForm from 'components/TheEntryForm'
import HorizontalScrollableTable from 'components/HorizontalScrollableTable'
import MapBasedSharingCandidateSelector from 'components/MapBasedSharingCandidateSelector'
import CommunityCard from 'components/CommunityCard'
import TheOrganizationForm from 'components/TheOrganizationForm'
import OrganizationMap from 'components/OrganizationMap'
import TheOrganization from 'components/TheOrganization'
import EmbedActionBox from 'components/EmbedActionBox'
import EmbedActionBoxes from 'components/EmbedActionBoxes'
import TheOnlineEventForm from 'components/TheOnlineEventForm'
import TheOnlineEvent from 'components/TheOnlineEvent'
import NewEventButton from 'components/NewEventButton'
import CorporateUserListItem from 'components/CorporateUserListItem'
import PartnerCorporateUserListItem from 'components/PartnerCorporateUserListItem'
import OrganizationCard from 'components/OrganizationCard'
import AttendOnlineEventButton from 'components/AttendOnlineEventButton'
import ExcludeTargetSelectInput from 'components/ExcludeTargetSelectInput'
import JoinOrganizationButton from 'components/JoinOrganizationButton'
import CommunityPrivacy from 'components/CommunityPrivacy'
import CommunityJoinPolicy from 'components/CommunityJoinPolicy'
import CommunityOrganizationName from 'components/CommunityOrganizationName'
import TopicForm from 'components/TopicForm'
import TopicCard from 'components/TopicCard'
import TheTopic from 'components/TheTopic'
import TopicComment from 'components/TopicComment'
import TopicSearch from 'components/TopicSearch'
import CorporateStaffCard from 'components/CorporateStaffCard'
import MessageModalLink from 'components/MessageModalLink'
import PasswordModalOk from 'components/PasswordModalOk'
import ActivityCard from 'components/ActivityCard'
import RangeSliderInput from 'components/RangeSliderInput'
import NewsReceiverSelector from 'components/NewsReceiverSelector'
import GuestEventEntry from 'components/GuestEventEntry'
import EventTypeToggle from 'components/EventTypeToggle'
import DynamicPhotoUploaderOuter from 'components/DynamicPhotoUploaderOuter'
import SignUpAddressInput from 'components/SignUpAddressInput'
import SignUpStatusNotice from 'components/SignUpStatusNotice'
import OrganizationJoinResponseLink from 'components/OrganizationJoinResponseLink'
import ReadMore from 'components/ReadMore'
import OrganizationJoinPolicyInput from 'components/OrganizationJoinPolicyInput'
import NestedOrganizationAutoJoinAddressesForm from 'components/NestedOrganizationAutoJoinAddressesForm'
import OrganizationList from 'components/OrganizationList'
import OrganizationListItem from 'components/OrganizationListItem'
import BlockUserOneToOneChat from 'components/BlockUserOneToOneChat'
import Introduction from 'components/Introduction'
import WelcomeMyCommu from 'components/WelcomeMyCommu'
import SignUpStep1 from 'components/SignUpStep1'
import BaseSelectNickname from 'components/BaseSelectNickname'
import TheTutorials from 'components/TheTutorials'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'
import store from 'store'
import MyCommuTopicDetail from 'components/my-commu/TopicDetail'
import ModalDeleteTopic from 'components/my-commu/ModalDeleteTopic'
import MyCommuFooter from 'components/my-commu/Footer'
import MyCommuBtnSeconDary from 'components/my-commu/BtnSecondary'
import MyCommuBanner from 'components/my-commu/Banner'
import MyCommuCardGive from 'components/my-commu/CardGive'
import MyCommuSearchAreaMenu from 'components/my-commu/SearchAreaMenu'
import MyCommuTabGroup from 'components/my-commu/TabGroup'
import MyCommuTabMinimal from 'components/my-commu/TabMinimal'
import MyCommuTopBar from 'components/my-commu/TopBar'
import MyCommuSideBar from 'components/my-commu/SideBar'
import MyCommuLoading from 'components/my-commu/Loading'
import MyCommuCardBorrow from 'components/my-commu/card/Borrow'
import MyCommuCardShare from 'components/my-commu/card/Share'
import MyCommuCardNews from 'components/my-commu/card/News'
import MyCommuCardEvent from 'components/my-commu/card/Event'
import MyCommuCardTranferChildCare from 'components/my-commu/card/TranferChildCare'
import MyCommuHome from 'page/Home'
import MyCommuCardDetail from 'page/CardDetail'
import HiddenHeader from 'components/my-commu/HiddenHeader'
import MyCommuTopBarTitle from 'components/my-commu/TopBarTitle'
import MyCommuHiddenButtonFooter from 'components/my-commu/HiddenButtonFooter'
import MyCommuHiddenFooterMenu from 'components/my-commu/HiddenFooterMenu'
import MyCommuBirthdayDatePicker from 'components/my-commu/BirthdayDatePicker'
import MyCommuSignUpStep3 from 'components/my-commu/SignUpStep3'
import MyCommuSignUpStep4 from 'components/my-commu/SignUpStep4'
import MyCommuMap from 'components/my-commu/MyCommuMap'
import MyCommuUser from 'page/User'
import MyCommuChat from 'page/Chat'
import MyCommuUserCard from 'components/my-commu/users/UserCard'
import MyCommuUserIndex from 'components/my-commu/users/Index'
import MyCommuChatTap from 'components/my-commu/chat/Tap'
import MyCommuChatIndex from 'components/my-commu/chat/Index'
import MyCommuChatCard from 'components/my-commu/chat/ChatCard'
import MyCommuChatCommunityTags from 'components/my-commu/chat/CommunityTags'
import MyCommuChatNewChat from 'components/my-commu/chat/NewChat'
import MyCommuChatDetail from 'components/my-commu/chat/ChatDetail'
import Vue2TouchEvents from 'vue2-touch-events'
import MyCommuSupporters from 'page/mama_supporter/Index'
import Supporter from 'components/my-commu/mama_supporter/Supporter'
import Tag from 'components/my-commu/mama_supporter/Tag'
import MyCommuSearchAreaMenuSupport from 'components/my-commu/SearchAreaMenuSupport'
import MyCommuChatsGuestMessage from 'components/my-commu/chat/GuestMessage'
import MyCommuChatsHostMessage from 'components/my-commu/chat/HostMessage'
import MyCommuInputMessageBox from 'components/my-commu/InputMessageBox'
import MyCommuGroupNew from 'page/CommunityGroups/New'
import MyCommuGroupDetail from 'page/CommunityGroups/Detail'
import MyCommuNewTopic from 'page/CommunityGroups/NewTopic'
import MyCommuGroupUsers from 'page/CommunityGroups/ListUser'
import MyCommuManager from 'page/management/Index'
import MyCommuCardTopic from 'components/my-commu/community_group/TopicGroup'
import MyCommuNotification from 'page/Notification'
import MyCommuGroupList from 'page/CommunityGroups/List'
import MyCommuNotificationNon from 'components/my-commu/notification/NoNotice'
import MyCommuNotificationFriendRequest from 'components/my-commu/notification/FriendRequest'
import MyCommuNotificationCard from 'components/my-commu/notification/NotificationCard'
import MyCommuGroupTopic from 'page/CommunityGroups/Topic'
import MyCommuManagementEvent from 'page/management/Event'
import MyCommuManagementEventNew from 'page/management/NewEvent'
import MyCommuStep from 'components/my-commu/Step'
import MyCommuManagementEventNewConfirm from 'components/my-commu/management/ConfirmEvent'
import MyCommuManagementEventNewStep3Location from 'components/my-commu/management/LocationEventStep3'
import MyCommuNewEventStep1 from 'components/my-commu/management/NewStep1'
import MyCommuNewEventStep2 from 'components/my-commu/management/NewStep2'
import MyCommuEventSchedules from 'components/my-commu/management/Schedules'
import MyCommuBaseDatePicker from 'components/my-commu/BaseDatePicker'
import MyCommuBaseButton from 'components/my-commu/BaseButton'
import MyCommuBaseTextInput from 'components/my-commu/BaseTextInput'
import MyCommuBaseSelectInput from 'components/my-commu/BaseSelectInput'
import MyCommuBaseRadio from 'components/my-commu/BaseRadio'
import MyCommuUsersChildren from 'components/my-commu/users/Children'
import MyCommuMyCommunities from 'components/my-commu/users/MyCommunities'
import MyCommuIntroductions from 'components/my-commu/users/Introductions'
import MyCommuQualifications from 'components/my-commu/users/Qualifications'
import MyCommuOptional from 'components/my-commu/Optional'
import MyCommuRequire from 'components/my-commu/Require'
import MyCommuManagementStaffCandidate from 'page/management/IndexStaffCandidate'
import MyCommuManagementCardEvent from 'components/my-commu/management/CardEvent'
import MyCommuManagementStaffCandidateShow from 'page/management/ShowStaffCandidate'
import MyCommuManagementListEvent from 'page/management/ListEvent'
import MyCommuStaffChat from 'components/my-commu/chat/StaffChat'
import MyCommuManagementExpenseNew from 'page/management/expenses/New'
import MyCommuGroupStatus from 'components/my-commu/community_group/GroupStatus'
import MyCommuGroupMenuBar from 'components/my-commu/community_group/MenuBar'
import MyCommuTop from 'page/new_session/Top'
import MyCommuInterested from 'components/my-commu/Interested'
import MyCommuEditChildProfile from 'page/children/EditChildProfile'
import MyCommuEditCommunityProfile from 'page/profile/EditCommunityProfile'
import MyCommuInformation from 'components/my-commu/users/Information'
import MyCommuProfile from 'page/user/Profile'
import MyCommuEventCancel from 'components/my-commu/event/CancelEvent'
import MyCommuJoinedUser from 'components/my-commu/JoinedUser'
import MyCommuEventDetail from 'page/EventDetail'
import MyCommuSharePost from 'page/SharePost'
import MyCommuFriendship from 'components/my-commu/users/FriendshipCommu'
import MyCommuChildTicket from 'components/my-commu/ticket/ChildTicket'
import MyCommuAllFriendship from 'page/profile/MyFriend'
import MyCommuOtp from 'components/my-commu/BaseOtp'
import MyCommuOtpInput from 'components/my-commu/BaseOtpInput'
import MyCommuCommunities from 'page/Communities'
import MyCommuTopicNew from 'components/my-commu/topic/New'
import MyCommuUserRequestsNew from 'components/my-commu/user_requests/New'
import MyCommuTopicEdit from 'components/my-commu/topic/Edit'
import MyCommuCommunityCard from 'components/my-commu/CommunityCard'
import MyCommuEditLicence from 'page/profile/EditLicence'
import MyCommuEditCommunityGroup from 'page/profile/EditCommunityGroup'
import MyCommuBaseCheckBox from 'components/my-commu/BaseCheckBox'
import MyCommuSharePostEveryThing from 'page/SharePost/EveryThing'
import MyCommuHistoriesSendsIndex from 'page/histories/sends/index'
import MyCommuNewTicket from 'page/event/NewTicket'
import MyCommuRequestCreateEvent from 'page/event/Request'
import MyCommuModalDelete from 'components/my-commu/BaseModalDelete'
import MyCommuWaitingRelease from 'components/my-commu/WaitingRelease'
import MyCommuSchedule from 'page/Schedule'
import MyCommuSharePostIndex from 'page/SharePostIndex'
import MyCommuGroupSharePost from 'page/CommunityGroups/SharePost'
import MyCommuBack from 'components/my-commu/Back'
import MyCommuListLike from 'page/ListLike'
import MyCommuCommentCard from 'page/comment/CommentCard'
import MyCommuCommentCard2 from 'page/comment/CommentCard2'
import MyCommuAreaPartners from 'page/AreaPartners'
import MyCommuGroupAreaPartners from 'page/CommunityGroups/GroupAreaPartners'
import MyCommuUserWithInterestCard from 'components/my-commu/users/UserWithInterestCard'
import InsufficientLevelFriendCard from 'components/my-commu/InsufficientLevelFriendCard'
import MyCommuSelfProfile from 'page/user/SelfProfile'
import MyCommuSelfInformation from 'components/my-commu/users/SelfInformation'
import MyCommuEditCommonProfile from 'page/profile/EditCommonProfile'
import MyCommuEditPhoneNumber from 'page/profile/EditPhoneNumber'
import MyCommuBankAccount from 'page/profile/EditBankAccount'
import Processing from 'components/my-commu/Processing'
import MyCommuOrganizationCard from 'components/my-commu/MyCommuOrganizationCard'
import MyCommuEventCard from 'components/my-commu/MyCommuEventCard'
import ShareConciergeCard from 'components/my-commu/ShareConciergeCard'
import UserDistributionMap from 'page/UserDistributionMap'
import MyCommuBlockList from 'page/profile/BlockList'
import MyCommuBlockUserCard from 'components/my-commu/users/BlockUserCard'
import NoticeSharingPost from 'components/my-commu/community_group/NoticeSharingPost'
import NoticeJoinGroup from 'components/my-commu/community_group/NoticeJoinGroup'
// import MyCommuScheduleNoticeModal from 'components/my-commu/ScheduleNoticeModal'
import MyCommuSchedules from 'page/Schedules'
import MyCommuSharePostDetail from 'page/SharePost/Detail'
import MyCommuShareComment from 'components/my-commu/comments/ShareComment'
import MyCommuCommunitySearch from 'page/CommunitySearch'
import Topic from 'components/my-commu/card/Topic'
import MyCommuTopicDetailNew from 'page/TopicDetail'

export default () => {
  if (window.APP.rails.user) {
    const { user, organizationId, organization, communityGroupId } = window.APP.rails
    store.commit('session/setCurrentUser', user)
    store.commit('session/setCommunityId', organizationId)
    store.commit('session/setCommunity', organization)
    store.commit('session/setCommunityGroupId', communityGroupId)
  }

  Vue.use(Buefy, {
    defaultDateFormatter: date => format(date, 'YYYY/MM/DD'),
    defaultDateParser: date => parse(date),
    defaultMonthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    defaultDayNames: ['日', '月', '火', '水', '木', '金', '土'],
    defaultIconPack: 'fal'
  })
  Vue.use(VueDisableInterpolation)
  Vue.use(PortalVue)
  Vue.use(Croppa)
  Vue.use(VueAwesomeSwiper, { slidesPerView: 1, spaceBetween: 0, pagination: {
    type: 'fraction'
  }})
  Vue.use(VueEasyLightbox)
  Vue.use(Vue2TouchEvents)

  Vue.component('ActionBox', ActionBox)
  Vue.component('ActionBoxBody', ActionBoxBody)
  Vue.component('ActionBoxes', ActionBoxes)
  Vue.component('TheActionBar', TheActionBar)
  Vue.component('HeaderH2', HeaderH2)
  Vue.component('HeaderH3', HeaderH3)
  Vue.component('HeaderH4', HeaderH4)
  Vue.component('Money', Money)
  Vue.component('ErrorNotification', ErrorNotification)
  Vue.component('SimpleErrorNotification', SimpleErrorNotification)
  Vue.component('BaseTextInput', BaseTextInput)
  Vue.component('BaseInputPassword', BaseInputPassword)
  Vue.component('BaseSwitchInput', BaseSwitchInput)
  Vue.component('BaseSelectInput', BaseSelectInput)
  Vue.component('BaseSelectInterestedFreeTag', BaseSelectInterestedFreeTag)
  Vue.component('BaseCheckboxInput', BaseCheckboxInput)
  Vue.component('BaseCheckboxButton', BaseCheckboxButton)
  Vue.component('BaseCheckboxesInput', BaseCheckboxesInput)
  Vue.component('BaseCounterButton', BaseCounterButton)
  Vue.component('BaseDateInput', BaseDateInput)
  Vue.component('BaseTimeInput', BaseTimeInput)
  Vue.component('BaseRadioInput', BaseRadioInput)
  Vue.component('BaseSubmitButton', BaseSubmitButton)
  Vue.component('BaseLabel', BaseLabel)
  Vue.component('DateTimePicker', DateTimePicker)
  Vue.component('DatePicker', DatePicker)
  Vue.component('Calendar', Calendar)
  Vue.component('BirthdayPicker', BirthdayPicker)
  Vue.component('NestedRequestSchedulesForm', NestedRequestSchedulesForm)
  Vue.component('NestedEventSchedulesForm', NestedEventSchedulesForm)
  // prettier-ignore
  Vue.component('NestedTicketChildProfilesForm', NestedTicketChildProfilesForm)
  Vue.component('NestedCorporateProfileForm', NestedCorporateProfileForm)
  Vue.component('NestedEventMailingListFilterForm', NestedEventMailingListFilterForm)
  Vue.component('NestedCouponForm', NestedCouponForm)
  Vue.component('TheChat', TheChat)
  Vue.component('ProfileSectionHierarchy', ProfileSectionHierarchy)
  Vue.component('CustomValueCheckboxInput', CustomValueCheckboxInput)
  Vue.component('TheCorporateUser', TheCorporateUser)
  Vue.component('TheApplication', TheApplication)
  Vue.component('TheCorpApplication', TheCorpApplication)
  Vue.component('InlineFrameContents', InlineFrameContents)
  Vue.component('TheProfileForm', TheProfileForm)
  Vue.component('TheProfile', TheProfile)
  Vue.component('TheEventForm', TheEventForm)
  Vue.component('TheAgeRangeForm', TheAgeRangeForm)
  Vue.component('FaceIcon', FaceIcon)
  Vue.component('FaceIconRelations', FaceIconRelations)
  Vue.component('EditableFaceIcon', EditableFaceIcon)
  Vue.component('EditPasswordForm', EditPasswordForm)
  Vue.component('EventDetailForMyCommu', EventDetailForMyCommu)
  Vue.component('EventTab', EventTab)
  Vue.component('EventList', EventList)
  Vue.component('EventListItem', EventListItem)
  Vue.component('EventListItemForMyCommu', EventListItemForMyCommu)
  Vue.component('AssignmentMailBodyInput', AssignmentMailBodyInput)
  Vue.component('TheCommunityForm', TheCommunityForm)
  Vue.component('Notice', Notice)
  Vue.component('FriendGauge', FriendGauge)
  Vue.component('BasePanelBlock', BasePanelBlock)
  Vue.component('TheCommunity', TheCommunity)
  Vue.component('BaseTab', BaseTab)
  Vue.component('TheBankAccountForm', TheBankAccountForm)
  Vue.component('SharingCandidateSelectInput', SharingCandidateSelectInput)
  Vue.component('HistoryActionBox', HistoryActionBox)
  Vue.component('BaseOptionButton', BaseOptionButton)
  Vue.component('BaseHelpButton', BaseHelpButton)
  Vue.component('BaseSearchButton', BaseSearchButton)
  Vue.component('ImageModal', ImageModal)
  Vue.component('TheBillingForm', TheBillingForm)
  Vue.component('ChildrenSelectInput', ChildrenSelectInput)
  Vue.component('SharingCandidateUser', SharingCandidateUser)
  Vue.component('TheEvent', TheEvent)
  Vue.component('TheEvents', TheEvents)
  Vue.component('LabelSection', LabelSection)
  Vue.component('TheHistory', TheHistory)
  Vue.component('TheTicketForm', TheTicketForm)
  Vue.component('TheNotification', TheNotification)
  Vue.component('TheEditStaffCandidateForm', TheEditStaffCandidateForm)
  Vue.component('ShareFriendCard', ShareFriendCard)
  Vue.component('ShareFriendCardModal', ShareFriendCardModal)
  Vue.component('ShareFriendCardModalLink', ShareFriendCardModalLink)
  Vue.component('ShareFriendAnimation', ShareFriendAnimation)
  Vue.component('TheFriendships', TheFriendships)
  Vue.component('PhotoUploaderBox', PhotoUploaderBox)
  Vue.component('PhotoUploaderOuter', PhotoUploaderOuter)
  Vue.component('TheSiteLaw', TheSiteLaw)
  Vue.component('TheInvitingForm', TheInvitingForm)
  Vue.component('AddonTextInput', AddonTextInput)
  Vue.component('PhotoUploader', PhotoUploader)
  Vue.component('TheChats', TheChats)
  Vue.component('SharingCard', SharingCard)
  Vue.component('RecommendNeighbor', RecommendNeighbor)
  Vue.component('AnnouncementList', AnnouncementList)
  Vue.component('UnregisteredMessage', UnregisteredMessage)
  Vue.component('CandidateLink', CandidateLink)
  Vue.component('EntryDetail', EntryDetail)
  Vue.component('SearchResultZero', SearchResultZero)
  Vue.component('CommunityParticipationUser', CommunityParticipationUser)
  Vue.component('InvitationSignIn', InvitationSignIn)
  Vue.component('SnsShareButton', SnsShareButton)
  Vue.component('HorizontalFaceIcons', HorizontalFaceIcons)
  Vue.component('TheShareableForm', TheShareableForm)
  Vue.component('LabeledActionBoxes', LabeledActionBoxes)
  Vue.component('MamaSupporterCard', MamaSupporterCard)
  Vue.component('SampleMark', SampleMark)
  Vue.component('TabNoIcon', TabNoIcon)
  Vue.component('TheUsersMap2', TheUsersMap2)
  Vue.component('MessageItem', MessageItem)
  Vue.component('SharingFriendButton', SharingFriendButton)
  Vue.component('ShareableCandidacyButton', ShareableCandidacyButton)
  Vue.component('Balloon', Balloon)
  Vue.component('ButtonSquareEdge', ButtonSquareEdge)
  Vue.component('StatGeoFields', StatGeoFields)
  Vue.component('MamaSupporterAbilities', MamaSupporterAbilities)
  Vue.component('DeviseNotSignedIn', DeviseNotSignedIn)
  Vue.component('TheChildProfileForm', TheChildProfileForm)
  Vue.component('TheEntryForm', TheEntryForm)
  Vue.component('HorizontalScrollableTable', HorizontalScrollableTable)
  Vue.component('MapBasedSharingCandidateSelector', MapBasedSharingCandidateSelector)
  Vue.component('CommunityCard', CommunityCard)
  Vue.component('TheOrganizationForm', TheOrganizationForm)
  Vue.component('OrganizationMap', OrganizationMap)
  Vue.component('TheOrganization', TheOrganization)
  Vue.component('EmbedActionBox', EmbedActionBox)
  Vue.component('EmbedActionBoxes', EmbedActionBoxes)
  Vue.component('TheOnlineEventForm', TheOnlineEventForm)
  Vue.component('TheOnlineEvent', TheOnlineEvent)
  Vue.component('NewEventButton', NewEventButton)
  Vue.component('CorporateUserListItem', CorporateUserListItem)
  Vue.component('PartnerCorporateUserListItem', PartnerCorporateUserListItem)
  Vue.component('OrganizationCard', OrganizationCard)
  Vue.component('AttendOnlineEventButton', AttendOnlineEventButton)
  Vue.component('ExcludeTargetSelectInput', ExcludeTargetSelectInput)
  Vue.component('JoinOrganizationButton', JoinOrganizationButton)
  Vue.component('CommunityPrivacy', CommunityPrivacy)
  Vue.component('CommunityJoinPolicy', CommunityJoinPolicy)
  Vue.component('CommunityOrganizationName', CommunityOrganizationName)
  Vue.component('TopicForm', TopicForm)
  Vue.component('TopicCard', TopicCard)
  Vue.component('TheTopic', TheTopic)
  Vue.component('TopicComment', TopicComment)
  Vue.component('TopicSearch', TopicSearch)
  Vue.component('CorporateStaffCard', CorporateStaffCard)
  Vue.component('MessageModalLink', MessageModalLink)
  Vue.component('PasswordModalOk', PasswordModalOk)
  Vue.component('ActivityCard', ActivityCard)
  Vue.component('RangeSliderInput', RangeSliderInput)
  Vue.component('NewsReceiverSelector', NewsReceiverSelector)
  Vue.component('GuestEventEntry', GuestEventEntry)
  Vue.component('EventTypeToggle', EventTypeToggle)
  Vue.component('DynamicPhotoUploaderOuter', DynamicPhotoUploaderOuter)
  Vue.component('SignUpAddressInput', SignUpAddressInput)
  Vue.component('SignUpStatusNotice', SignUpStatusNotice)
  Vue.component('OrganizationJoinResponseLink', OrganizationJoinResponseLink)
  Vue.component('ReadMore', ReadMore)
  Vue.component('OrganizationJoinPolicyInput', OrganizationJoinPolicyInput)
  Vue.component('NestedOrganizationAutoJoinAddressesForm', NestedOrganizationAutoJoinAddressesForm)
  Vue.component('OrganizationList', OrganizationList)
  Vue.component('OrganizationListItem', OrganizationListItem)
  Vue.component('BlockUserOneToOneChat', BlockUserOneToOneChat)
  Vue.component('Introduction', Introduction)
  Vue.component('WelcomeMyCommu', WelcomeMyCommu)
  Vue.component('SignUpStep1', SignUpStep1)
  Vue.component('BaseSelectNickname', BaseSelectNickname)
  Vue.component('TheTutorials', TheTutorials)
  Vue.component('MyCommuFooter', MyCommuFooter)
  Vue.component('MyCommuBtnSeconDary', MyCommuBtnSeconDary)
  Vue.component('MyCommuBanner', MyCommuBanner)
  Vue.component('MyCommuCardGive', MyCommuCardGive)
  Vue.component('MyCommuSearchAreaMenu', MyCommuSearchAreaMenu)
  Vue.component('MyCommuTabGroup', MyCommuTabGroup)
  Vue.component('MyCommuTabMinimal', MyCommuTabMinimal)
  Vue.component('MyCommuTopBar', MyCommuTopBar)
  Vue.component('MyCommuSideBar', MyCommuSideBar)
  Vue.component('MyCommuLoading', MyCommuLoading)
  Vue.component('MyCommuCardBorrow', MyCommuCardBorrow)
  Vue.component('MyCommuCardShare', MyCommuCardShare)
  Vue.component('MyCommuCardNews', MyCommuCardNews)
  Vue.component('MyCommuCardEvent', MyCommuCardEvent)
  Vue.component('MyCommuCardTranferChildCare', MyCommuCardTranferChildCare)
  Vue.component('MyCommuHome', MyCommuHome)
  Vue.component('MyCommuSupporters', MyCommuSupporters)
  Vue.component('MyCommuCardDetail', MyCommuCardDetail)

  Vue.component('MyCommuTopicDetail', MyCommuTopicDetail)
  Vue.component('ModalDeleteTopic', ModalDeleteTopic)

  Vue.component('HiddenHeader', HiddenHeader)
  Vue.component('MyCommuTopBarTitle', MyCommuTopBarTitle)
  Vue.component('MyCommuHiddenButtonFooter', MyCommuHiddenButtonFooter)
  Vue.component('MyCommuHiddenFooterMenu', MyCommuHiddenFooterMenu)
  Vue.component('MyCommuBirthdayDatePicker', MyCommuBirthdayDatePicker)
  Vue.component('MyCommuSignUpStep3', MyCommuSignUpStep3)
  Vue.component('MyCommuSignUpStep4', MyCommuSignUpStep4)
  Vue.component('MyCommuMap', MyCommuMap)
  Vue.component('MyCommuUser', MyCommuUser)
  Vue.component('MyCommuChat', MyCommuChat)
  Vue.component('MyCommuUserIndex', MyCommuUserIndex)
  Vue.component('MyCommuChatTap', MyCommuChatTap)
  Vue.component('MyCommuUserCard', MyCommuUserCard)
  Vue.component('Supporter', Supporter)
  Vue.component('Tag', Tag)
  Vue.component('MyCommuSearchAreaMenuSupport', MyCommuSearchAreaMenuSupport)
  Vue.component('MyCommuChatIndex', MyCommuChatIndex)
  Vue.component('MyCommuChatCard', MyCommuChatCard)
  Vue.component('MyCommuChatCommunityTags', MyCommuChatCommunityTags)
  Vue.component('MyCommuChatNewChat', MyCommuChatNewChat)
  Vue.component('MyCommuGroupNew', MyCommuGroupNew)
  Vue.component('MyCommuGroupUsers', MyCommuGroupUsers)
  Vue.component('MyCommuGroupDetail', MyCommuGroupDetail)
  Vue.component('MyCommuCardTopic', MyCommuCardTopic)
  Vue.component('MyCommuNewTopic', MyCommuNewTopic)
  Vue.component('MyCommuGroupList', MyCommuGroupList)
  Vue.component('MyCommuChatDetail', MyCommuChatDetail)
  Vue.component('MyCommuChatsGuestMessage', MyCommuChatsGuestMessage)
  Vue.component('MyCommuChatsHostMessage', MyCommuChatsHostMessage)
  Vue.component('MyCommuInputMessageBox', MyCommuInputMessageBox)
  Vue.component('MyCommuGroupTopic', MyCommuGroupTopic)
  Vue.component('MyCommuManager', MyCommuManager)
  Vue.component('MyCommuManagementEvent', MyCommuManagementEvent)
  Vue.component('MyCommuManagementEventNew', MyCommuManagementEventNew)
  Vue.component('MyCommuStep', MyCommuStep)
  Vue.component('MyCommuNotification', MyCommuNotification)
  Vue.component('MyCommuNotificationNon', MyCommuNotificationNon)
  Vue.component('MyCommuNotificationFriendRequest', MyCommuNotificationFriendRequest)
  Vue.component('MyCommuNotificationCard', MyCommuNotificationCard)
  Vue.component('MyCommuManagementEventNewConfirm', MyCommuManagementEventNewConfirm)
  Vue.component('MyCommuManagementEventNewStep3Location', MyCommuManagementEventNewStep3Location)
  Vue.component('MyCommuNewEventStep1', MyCommuNewEventStep1)
  Vue.component('MyCommuNewEventStep2', MyCommuNewEventStep2)
  Vue.component('MyCommuEventSchedules', MyCommuEventSchedules)
  Vue.component('MyCommuManagementStaffCandidate', MyCommuManagementStaffCandidate)
  Vue.component('MyCommuManagementCardEvent', MyCommuManagementCardEvent)
  Vue.component('MyCommuManagementStaffCandidateShow', MyCommuManagementStaffCandidateShow)
  Vue.component('MyCommuManagementListEvent', MyCommuManagementListEvent)
  Vue.component('MyCommuBaseDatePicker', MyCommuBaseDatePicker)
  Vue.component('MyCommuBaseButton', MyCommuBaseButton)
  Vue.component('MyCommuBaseTextInput', MyCommuBaseTextInput)
  Vue.component('MyCommuBaseSelectInput', MyCommuBaseSelectInput)
  Vue.component('MyCommuBaseRadio', MyCommuBaseRadio)
  Vue.component('MyCommuUsersChildren', MyCommuUsersChildren)
  Vue.component('MyCommuMyCommunities', MyCommuMyCommunities)
  Vue.component('MyCommuIntroductions', MyCommuIntroductions)
  Vue.component('MyCommuManagementExpenseNew', MyCommuManagementExpenseNew)
  Vue.component('MyCommuStaffChat', MyCommuStaffChat)
  Vue.component('MyCommuGroupStatus', MyCommuGroupStatus)
  Vue.component('MyCommuGroupMenuBar', MyCommuGroupMenuBar)
  Vue.component('MyCommuInterested', MyCommuInterested)
  Vue.component('MyCommuTop', MyCommuTop)
  Vue.component('MyCommuInformation', MyCommuInformation)
  Vue.component('MyCommuProfile', MyCommuProfile)
  Vue.component('MyCommuEventCancel', MyCommuEventCancel)
  Vue.component('MyCommuJoinedUser', MyCommuJoinedUser)
  Vue.component('MyCommuEventDetail', MyCommuEventDetail)
  Vue.component('MyCommuQualifications', MyCommuQualifications)
  Vue.component('MyCommuEditChildProfile', MyCommuEditChildProfile)
  Vue.component('MyCommuEditCommunityProfile', MyCommuEditCommunityProfile)
  Vue.component('MyCommuOptional', MyCommuOptional)
  Vue.component('MyCommuRequire', MyCommuRequire)
  Vue.component('MyCommuSharePost', MyCommuSharePost)
  Vue.component('MyCommuFriendship', MyCommuFriendship)
  Vue.component('MyCommuChildTicket', MyCommuChildTicket)
  Vue.component('MyCommuAllFriendship', MyCommuAllFriendship)
  Vue.component('MyCommuOtp', MyCommuOtp)
  Vue.component('MyCommuOtpInput', MyCommuOtpInput)
  Vue.component('MyCommuCommunities', MyCommuCommunities)
  Vue.component('MyCommuTopicNew', MyCommuTopicNew)
  Vue.component('MyCommuUserRequestsNew', MyCommuUserRequestsNew)
  Vue.component('MyCommuTopicEdit', MyCommuTopicEdit)
  Vue.component('MyCommuCommunityCard', MyCommuCommunityCard)
  Vue.component('MyCommuEditLicence', MyCommuEditLicence)
  Vue.component('MyCommuEditCommunityGroup', MyCommuEditCommunityGroup)
  Vue.component('MyCommuBaseCheckBox', MyCommuBaseCheckBox)
  Vue.component('MyCommuSharePostEveryThing', MyCommuSharePostEveryThing)
  Vue.component('MyCommuHistoriesSendsIndex', MyCommuHistoriesSendsIndex)
  Vue.component('MyCommuNewTicket', MyCommuNewTicket)
  Vue.component('MyCommuRequestCreateEvent', MyCommuRequestCreateEvent)
  Vue.component('MyCommuSchedule', MyCommuSchedule)
  Vue.component('MyCommuModalDelete', MyCommuModalDelete)
  Vue.component('MyCommuWaitingRelease', MyCommuWaitingRelease)
  Vue.component('MyCommuSharePostIndex', MyCommuSharePostIndex)
  Vue.component('NoticeJoinGroup', NoticeJoinGroup)
  Vue.component('NoticeSharingPost', NoticeSharingPost)
  Vue.component('MyCommuBack', MyCommuBack)
  Vue.component('MyCommuGroupSharePost', MyCommuGroupSharePost)
  Vue.component('MyCommuListLike', MyCommuListLike)
  Vue.component('MyCommuCommentCard', MyCommuCommentCard)
  Vue.component('MyCommuCommentCard2', MyCommuCommentCard2)
  Vue.component('MyCommuAreaPartners', MyCommuAreaPartners)
  Vue.component('MyCommuGroupAreaPartners', MyCommuGroupAreaPartners)
  Vue.component('MyCommuUserWithInterestCard', MyCommuUserWithInterestCard)
  Vue.component('InsufficientLevelFriendCard', InsufficientLevelFriendCard)
  Vue.component('MyCommuSelfProfile', MyCommuSelfProfile)
  Vue.component('MyCommuSelfInformation', MyCommuSelfInformation)
  Vue.component('MyCommuEditCommonProfile', MyCommuEditCommonProfile)
  Vue.component('MyCommuEditPhoneNumber', MyCommuEditPhoneNumber)
  Vue.component('MyCommuBankAccount', MyCommuBankAccount)
  Vue.component('Processing', Processing)
  Vue.component('MyCommuOrganizationCard', MyCommuOrganizationCard)
  Vue.component('MyCommuEventCard', MyCommuEventCard)
  Vue.component('ShareConciergeCard', ShareConciergeCard)
  Vue.component('UserDistributionMap', UserDistributionMap)
  Vue.component('MyCommuBlockList', MyCommuBlockList)
  Vue.component('MyCommuBlockUserCard', MyCommuBlockUserCard)
  Vue.component('MyCommuShareComment', MyCommuShareComment)

  Vue.component('MyCommuSchedules', MyCommuSchedules)
  Vue.component('MyCommuSharePostDetail', MyCommuSharePostDetail)
  Vue.component('MyCommuCommunitySearch', MyCommuCommunitySearch)
  Vue.component('Topic', Topic)
  Vue.component('MyCommuTopicDetailNew', MyCommuTopicDetailNew)

  document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('#vue-app')
    /* eslint-disable no-new */
    new Vue({ el, store })
  })
}
